<template>
  <v-card>
    <v-card-title>
      <v-btn
        v-show="selectedProjects.length"
        class="mr-6"
        icon
        color="error"
        @click="tryDeleteManyProjects(selectedProjects)"
      >
        <v-icon>{{ icons.mdiDeleteForever }}</v-icon>
      </v-btn>
      <TableSearch
        v-model="tableOptions.search"
        hide-filter
      />
      <v-spacer />
      <AddNewProject
        @submitted="tryFetchProjects"
      />
    </v-card-title>
    <v-card-text>
      <v-data-table
        v-model="selectedProjects"
        hide-default-footer
        :mobile-breakpoint="0"
        item-key="id"
        loading-text="Loading projects ..."
        :loading="loading"
        :items="projects"
        :headers="projectHeaders"
        :server-items-length="total"
        :options.sync="tableOptions"
        :footer-props="{
          'items-per-page-options': pages,
          'show-first-last-page': true,
          'show-current-page': true,
        }"

        @update:options="updateList"
      >
        <!-- SELECT ROW-->
        <template v-slot:header.data-table-select="{ props, on }">
          <CustomSelectRows
            :value="props"
            :page="tableOptions.page"
            :total="total"
            v-on="on"
          />
        </template>
        <template v-slot:item.name="{ item, value }">
          <slot>
            <v-chip
              :to="{name: 'discordProjectDetails', params: {id: item.id}}"
            >
              <span>{{ value }}</span>
              <v-icon
                small
                class="ml-1"
                color="primary"
              >
                {{ icons.mdiOpenInNew }}
              </v-icon>
            </v-chip>
          </slot>
        </template>
        <template v-slot:item.action="{ item }">
          <TableActions
            :loading="item.loading"
            @edit="openProjectEditModal(item)"
            @delete="tryDeleteProject(item.id)"
          />
        </template>
        <template v-slot:footer="{ props, on }">
          <TableFooter
            v-bind="props"
            v-on="on"
          />
        </template>
      </v-data-table>
    </v-card-text>

    <ProjectEditModal
      ref="editProjectModal"
      @update="tryUpdateProject"
    />

    <ConfirmDialog
      ref="deleteProjectConfirm"
      max-width="400"
    >
      <template v-slot="{agree, cancel}">
        <v-card>
          <v-card-title>Delete project</v-card-title>
          <v-card-text>
            Do you really want to delete project
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="error"
              @click="agree"
            >
              <v-icon>{{ icons.mdiDeleteForever }}</v-icon>
              Delete
            </v-btn>
            <v-btn
              @click="cancel"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </ConfirmDialog>
  </v-card>
</template>

<script>
import {
  mdiDeleteForever,
  mdiCheck,
  mdiCheckCircleOutline,
  mdiLockOutline,
  mdiDotsVertical,
  mdiPencil,
  mdiMagnify,
  mdiShieldOffOutline,
  mdiCellphoneKey,
  mdiOpenInNew,
  mdiEmailCheckOutline,
  mdiCloseCircleOutline,
  mdiCloseOctagonOutline,
} from '@mdi/js'
import pick from 'lodash/pick'
import { mapActions, mapMutations, mapState } from 'vuex'
import store from '@/store'
import discordStoreModule, { DISCORD_MODULE_NAME } from '@/store/modules/discordStoreModule'

import CustomSelectRows from '@/components/CustomSelectRows.vue'

import AddNewProject from '@/components/discord/project/AddNew.vue'
import ProjectEditModal from '@/components/discord/project/EditModal.vue'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import TableActions from '@/components/TableActions.vue'
import TableFooter from '@/components/TableFooter.vue'
import TableSearch from '@/components/TableSearch.vue'

export default {
  components: {
    CustomSelectRows,
    AddNewProject,
    ProjectEditModal,
    ConfirmDialog,
    TableActions,
    TableFooter,
    TableSearch,
  },
  data() {
    return {
      loading: true,
      total: 50,
      tableOptions: {
        sortBy: this.$route.query.sortBy ? [this.$route.query.sortBy] : [],
        sortDesc: +this.$route.query.sortDesc ? [Boolean(+this.$route.query.sortDesc)] : [],
        page: +this.$route.query.page ? +this.$route.query.page : 1,
        itemsPerPage: +this.$route.query.itemsPerPage ? +this.$route.query.itemsPerPage : localStorage.getItem('itemsPerPage') || 50,
        search: this.$route.query.search || '',
      },
      search: '',
      selectedProjects: [],
      pages: [50, 100, 200],
      verificationIcons: {
        'phone verified': mdiCellphoneKey,
        'mail verified': mdiEmailCheckOutline,
        'non verified': mdiShieldOffOutline,
        null: mdiCloseOctagonOutline,
      },
      icons: {
        mdiDotsVertical,
        mdiDeleteForever,
        mdiPencil,
        mdiMagnify,
        mdiLockOutline,
        mdiCheck,
        mdiCloseCircleOutline,
        mdiCheckCircleOutline,
        mdiOpenInNew,
      },
      projectHeaders: [
        {
          text: 'Name',
          value: 'name',
          sortable: true,
          filterable: true,
        },
        {
          text: 'Invitation Code',
          value: 'invitation_code',
          sortable: true,
          filterable: true,
          align: 'start',
        },

        {
          text: 'Verification Type',
          value: 'verification_type',
          sortable: true,
          filterable: true,
        },
        {
          align: 'end',
          value: 'action',
          sortable: false,
        },
      ],
    }
  },

  computed: {
    ...mapState({
      projects(state) {
        return state[DISCORD_MODULE_NAME]?.project.projects
      },
    }),
  },

  watch: {
    total() {
      this.tableOptions.page = 1
    },
    tableOptions: {
      handler(value) {
        const query = pick(value, ['sortBy', 'page', 'search', 'status'])

        query.limit = value.itemsPerPage

        if (value.sortBy?.length) {
          [query.sortBy] = value.sortBy
        }

        if (value.sortDesc?.length) {
          [query.sortDesc] = value.sortDesc
        }

        Object.keys(query).forEach(key => {
          if (!query[key] && key !== 'sortDesc') {
            delete query[key]
          }
        })

        this.tryFetchProjects(query)
        this.$router.replace({ query }).catch(() => {})
      },
      deep: true,
    },
  },

  mounted() {
    // Register module
    if (!store.hasModule(DISCORD_MODULE_NAME)) {
      store.registerModule(DISCORD_MODULE_NAME, discordStoreModule)
    }
  },

  methods: {
    ...mapActions({
      fetchProjects(dispatch, payload) {
        return dispatch(`${DISCORD_MODULE_NAME}/fetchProjects`, payload)
      },
      updateProject(dispatch, payload) {
        return dispatch(`${DISCORD_MODULE_NAME}/updateProject`, payload)
      },
      removeProject(dispatch, payload) {
        return dispatch(`${DISCORD_MODULE_NAME}/removeProject`, payload)
      },
    }),

    ...mapMutations({
      commitSetProjects(commit, payload) {
        return commit(`${DISCORD_MODULE_NAME}/SET_PROJECTS `, payload)
      },
      commitEditProject(commit, payload) {
        return commit(`${DISCORD_MODULE_NAME}/EDIT_PROJECT`, payload)
      },
      commitDeleteProject(commit, payload) {
        return commit(`${DISCORD_MODULE_NAME}/REMOVE_PROJECT`, payload)
      },
    }),

    updateList() {

    },

    async tryFetchProjects(params = this.$route.query) {
      this.loading = true
      const { data } = await this.fetchProjects(params)
      this.total = data.total
      this.loading = false
    },

    async tryDeleteProject(id) {
      this.$refs.deleteProjectConfirm.open().then(() => {
        this.commitEditProject({ id })
        this.removeProject({ id }).then(() => {
          this.commitDeleteProject({ id })
          this.$message.success('Project deleted successfully')
        }).catch(err => this.$message.error(err))
      })
    },

    async tryUpdateProject(value) {
      this.commitEditProject({ ...value, loading: true })
      const { data } = await this.updateProject({ project: value })
      this.commitEditProject(data)
    },

    openProjectEditModal(project) {
      this.$refs.editProjectModal.open(project)
    },
  },
}
</script>

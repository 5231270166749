var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedProjects.length),expression:"selectedProjects.length"}],staticClass:"mr-6",attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.tryDeleteManyProjects(_vm.selectedProjects)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDeleteForever))])],1),_c('TableSearch',{attrs:{"hide-filter":""},model:{value:(_vm.tableOptions.search),callback:function ($$v) {_vm.$set(_vm.tableOptions, "search", $$v)},expression:"tableOptions.search"}}),_c('v-spacer'),_c('AddNewProject',{on:{"submitted":_vm.tryFetchProjects}})],1),_c('v-card-text',[_c('v-data-table',{attrs:{"hide-default-footer":"","mobile-breakpoint":0,"item-key":"id","loading-text":"Loading projects ...","loading":_vm.loading,"items":_vm.projects,"headers":_vm.projectHeaders,"server-items-length":_vm.total,"options":_vm.tableOptions,"footer-props":{
        'items-per-page-options': _vm.pages,
        'show-first-last-page': true,
        'show-current-page': true,
      }},on:{"update:options":[function($event){_vm.tableOptions=$event},_vm.updateList]},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(ref){
      var props = ref.props;
      var on = ref.on;
return [_c('CustomSelectRows',_vm._g({attrs:{"value":props,"page":_vm.tableOptions.page,"total":_vm.total}},on))]}},{key:"item.name",fn:function(ref){
      var item = ref.item;
      var value = ref.value;
return [_vm._t("default",function(){return [_c('v-chip',{attrs:{"to":{name: 'discordProjectDetails', params: {id: item.id}}}},[_c('span',[_vm._v(_vm._s(value))]),_c('v-icon',{staticClass:"ml-1",attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiOpenInNew)+" ")])],1)]})]}},{key:"item.action",fn:function(ref){
      var item = ref.item;
return [_c('TableActions',{attrs:{"loading":item.loading},on:{"edit":function($event){return _vm.openProjectEditModal(item)},"delete":function($event){return _vm.tryDeleteProject(item.id)}}})]}},{key:"footer",fn:function(ref){
      var props = ref.props;
      var on = ref.on;
return [_c('TableFooter',_vm._g(_vm._b({},'TableFooter',props,false),on))]}}],null,true),model:{value:(_vm.selectedProjects),callback:function ($$v) {_vm.selectedProjects=$$v},expression:"selectedProjects"}})],1),_c('ProjectEditModal',{ref:"editProjectModal",on:{"update":_vm.tryUpdateProject}}),_c('ConfirmDialog',{ref:"deleteProjectConfirm",attrs:{"max-width":"400"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var agree = ref.agree;
      var cancel = ref.cancel;
return [_c('v-card',[_c('v-card-title',[_vm._v("Delete project")]),_c('v-card-text',[_vm._v(" Do you really want to delete project ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"},on:{"click":agree}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDeleteForever))]),_vm._v(" Delete ")],1),_c('v-btn',{on:{"click":cancel}},[_vm._v(" Cancel ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }